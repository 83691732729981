import React from "react";

// Import images using relative paths
import step1 from "../Image/step1.png";
import step2 from "../Image/step2.png";
import step3 from "../Image/step3.png";
import step4 from "../Image/step4.png";
import step5 from "../Image/step5.png";
import step6 from "../Image/step6.png";

const steps = [
  { text: "Open Reker", img: step1 },
  { text: "Go to Profile", img: step2 },
  { text: "Tap on the gear icon in the top right corner", img: step3 },
  { text: "Tap on the 'Delete Account' button", img: step4 },
  { text: "Provide a reason for account deletion. Click 'Delete Account Permanently'", img: step5 },
  { text: "Receive an OTP on your registered mobile number. Enter the OTP for verification. Upon successful OTP verification, your account will be deleted", img: step6 },
];

const DeactivateAccount: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center py-10 px-6 md:px-12 bg-gray-100">
      <div className="max-w-3xl w-full bg-white shadow-lg rounded-lg p-8">
        

        <h2 className="text-lg font-semibold text-gray-700 mt-8 mb-4">1. Steps to Delete Your Account</h2>
        <div className="space-y-8">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center text-center border-b pb-6 last:border-none">
              <img src={step.img} alt={`Step ${index + 1}`} className="w-[5in] h-[5in] rounded-lg shadow-md mb-4" />
              <p className="text-sm font-medium text-gray-700">{step.text}</p>
            </div>
          ))}
        </div>

        <h2 className="text-lg font-semibold text-gray-700 mt-8 mb-3">2. What Gets Deleted</h2>
        <ul className="list-disc list-inside text-red-500 text-sm pl-4">
          <li>Your profile information</li>
          <li>Your saved coins</li>
        </ul>

        <h2 className="text-lg font-semibold text-gray-700 mt-8 mb-3">3. What We Retain</h2>
        <ul className="list-disc list-inside text-gray-700 text-sm pl-4">
          <li>Transaction records (retained for 3 years for legal compliance)</li>
          <li>Anonymous usage statistics</li>
        </ul>

        <h2 className="text-lg font-semibold text-gray-700 mt-8 mb-3">4. Important Considerations</h2>
        <p className="text-sm text-gray-600 mb-4">
          Once your account is deleted, it cannot be recovered. Ensure you back up any important information before proceeding.
        </p>

        <h2 className="text-lg font-semibold text-gray-700 mt-8 mb-3">5. Changes to This Process</h2>
        <p className="text-sm text-gray-600 mb-4">
          We may modify the account deletion process from time to time. Please check this page periodically for updates.
        </p>

        <h2 className="text-lg font-semibold text-gray-700 mt-8 mb-3">6. Contact Us</h2>
        <p className="text-sm text-gray-600">
          If you have any questions, please contact us at:
        </p>
        <p className="text-sm text-blue-600">support@reker.world</p>
      </div>
    </div>
  );
};

export default DeactivateAccount;