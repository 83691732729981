import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faRedditAlien } from "@fortawesome/free-brands-svg-icons";

const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="container mx-auto px-6 lg:px-12">
        <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left">
          {/* Navigation Links */}
          <nav className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6 text-gray-400 text-sm mb-4 md:mb-0">
            <Link to="/terms-and-conditions" className="hover:text-white" onClick={scrollToTop}>
              Terms & Conditions
            </Link>
            <Link to="/privacy-policy" className="hover:text-white" onClick={scrollToTop}>
              Privacy Policy
            </Link>
            <Link to="/refund-policy" className="hover:text-white" onClick={scrollToTop}>
              Refund Policy
            </Link>
            <Link to="/delete-account" className="hover:text-white" onClick={scrollToTop}>
              Delete Account
            </Link>
          </nav>

          {/* Social Media Links */}
          <div className="flex space-x-6">
          <a
                href="https://www.instagram.com/reker.world"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                title="Instagram"
                className="transition duration-300 transform hover:scale-125"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 448 512"
                >
                  <defs>
                    <linearGradient
                      id="instagram-gradient-final"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop offset="0%" stopColor="#f09433" />
                      <stop offset="30%" stopColor="#e6683c" />
                      <stop offset="60%" stopColor="#dc2743" />
                      <stop offset="100%" stopColor="#bc1888" />
                    </linearGradient>
                  </defs>
                  <path
                    fill="url(#instagram-gradient-final)"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9 114.9-51.3 114.9-114.9-51.3-114.9-114.9-114.9zm0 186.4c-39.5 0-71.5-32-71.5-71.5s32-71.5 71.5-71.5 71.5 32 71.5 71.5-32 71.5-71.5 71.5zm146.4-194.3c0 14.9-12.1 27-27 27s-27-12.1-27-27 12.1-27 27-27 27 12.1 27 27zm76.1 27.2c-1.7-35.9-9.9-67.8-36.2-94.1-26.3-26.3-58.2-34.5-94.1-36.2-37.2-2.1-148.8-2.1-186 0-35.9 1.7-67.8 9.9-94.1 36.2s-34.5 58.2-36.2 94.1c-2.1 37.2-2.1 148.8 0 186 1.7 35.9 9.9 67.8 36.2 94.1s58.2 34.5 94.1 36.2c37.2 2.1 148.8 2.1 186 0 35.9-1.7 67.8-9.9 94.1-36.2 26.3-26.3 34.5-58.2 36.2-94.1 2.1-37.2 2.1-148.8 0-186zm-48.6 224.5c-7.8 19.6-22.9 34.7-42.5 42.5-29.4 11.7-99.2 9-132.3 9s-102.9 2.6-132.3-9c-19.6-7.8-34.7-22.9-42.5-42.5-11.7-29.4-9-99.2-9-132.3s-2.6-102.9 9-132.3c7.8-19.6 22.9-34.7 42.5-42.5 29.4-11.7 99.2-9 132.3-9s102.9-2.6 132.3 9c19.6 7.8 34.7 22.9 42.5 42.5 11.7 29.4 9 99.2 9 132.3s2.7 102.9-9 132.3z"
                  />
                </svg>
              </a>
              <a
              href="https://www.youtube.com/@rekerworld"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
              className="transition-transform duration-300 hover:scale-125"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="#FF0000" d="M23.5 6.2a3 3 0 0 0-2.1-2.1C19.2 4 12 4 12 4s-7.2 0-9.4.1a3 3 0 0 0-2.1 2.1C.2 7.3 0 8.5 0 12s.2 4.7.5 5.8a3 3 0 0 0 2.1 2.1C4.8 20 12 20 12 20s7.2 0 9.4-.1a3 3 0 0 0 2.1-2.1c.3-1.2.5-2.3.5-5.8s-.2-4.7-.5-5.8zM9 15V9l6 3-6 3z"/>
              </svg>
            </a>
            <a
              href="https://www.x.com/rekerworld"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="X"
              className="transition-transform duration-300 hover:scale-125"
            >
              <FontAwesomeIcon icon={faXTwitter} size="lg" color="#1DA1F2" />
            </a>
            <a
              href="https://www.reddit.com/user/rekerworld"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Reddit"
              className="transition-transform duration-300 hover:scale-125"
            >
              <FontAwesomeIcon icon={faRedditAlien} size="lg" color="#FF4500" />
            </a>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-6 pt-4 text-center text-gray-400 text-sm">
          <p>&copy; {new Date().getFullYear()} Evolvevol Tech Pvt. Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;