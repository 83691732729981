import React from "react";

const RefundPolicy: React.FC = () => {
  return (
    <div className="mt-14 max-w-4xl mx-auto space-y-8">
      <section className="bg-white shadow-xl rounded-lg p-8">
        <p className="text-sm text-gray-600 mb-4">
          At REKER, we are committed to ensuring a seamless and transparent experience for our users and partnered merchant stores.
        </p>
        <p className="text-sm text-gray-600 mb-8">
          This policy outlines the terms governing refunds, cancellations, and settlement procedures. All payments are securely processed through Cashfree, our authorized payment gateway partner.
        </p>

        <div className="space-y-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-800">1. Coupon Redemption Policy</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>Once a user redeems their coins for a coupon, the transaction is considered final and non-reversible.</li>
              <li>Redeemed coupons cannot be exchanged, reinstated, or transferred under any circumstances.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">2. Payment Cancellation and Refunds</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>Users may request a cancellation of their payment in accordance with the refund policy of the partnered merchant store.</li>
              <li>If eligible, refunds for payments made via REKER will be processed through Cashfree as per the merchant’s terms.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">3. Coupon Validity and Expiry</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>Coupons issued through the app come with a predefined validity period.</li>
              <li>Expired coupons are non-refundable and non-extendable.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">4. Merchant-Initiated Payment Reversals</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>If a partnered merchant store cancels a payment after it has been processed, the refund will be governed by the store’s refund policy.</li>
              <li>Users are encouraged to review the merchant’s terms before making a payment.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">5. Discount Settlement Process</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>The app facilitates discount settlements through Cashfree to ensure timely and secure transactions.</li>
              <li>Once the discount amount is transferred to the merchant, it becomes non-reversible, except in cases where the full payment is refunded by the merchant.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">6. Refund Processing Timeline</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>Refunds initiated by a partnered merchant store (if applicable) will be processed within 5-7 business days through Cashfree.</li>
              <li>Processing timelines may vary depending on the user’s payment provider and bank policies.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">7. Technical Errors and Payment Discrepancies</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>In cases of payment failure where funds are deducted, refunds will be automatically processed through Cashfree within 7 business days.</li>
              <li>Users are advised to reach out to customer support if the refund is not received within the stipulated timeframe.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">8. Unauthorized or Fraudulent Transactions</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>If a user identifies an unauthorized deduction of coins or payment, they must report the issue immediately.</li>
              <li>A thorough investigation will be conducted, and if found valid, a refund may be issued within 10-15 business days.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">9. Policy Amendments</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>REKER reserves the right to update or modify this policy at any time.</li>
              <li>Any changes will be communicated via the website and other relevant channels.</li>
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-800">10. Customer Support and Assistance</h3>
            <ul className="list-inside list-disc text-xs text-gray-700">
              <li>For any refund or cancellation inquiries, users may contact our customer support team at <span className="text-blue-600">support@reker.world</span>.</li>
              <li>Our team aims to respond within 24-48 hours on business days.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RefundPolicy;
