import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
      <div className="mt-12 max-w-4xl mx-auto space-y-6">
        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            1. Information We Collect
          </h2>

          <h3 className="text-sm font-semibold text-black mt-4 mb-2">a. Location Data</h3>
          <p className="text-xs text-black">
            We collect precise and approximate location data from your device in
            order to provide you with services that are tailored to your
            location, such as location-based features, real-time map
            interactions, and proximity-based functionalities that enhance the
            user experience. This data is collected only with your consent, and
            you have the ability to enable or disable location services through
            your device's settings at any time.
          </p>

          <h3 className="text-sm font-semibold text-black mt-4 mb-2">
            b. Financial Information
          </h3>
          <p className="text-xs text-black">
            When you make in-app purchases or engage in any financial
            transactions within the App, we may collect payment-related
            information such as your credit card details, PayPal account
            information, or other payment methods. This information is processed
            securely by third-party payment processors that specialize in
            handling financial data. Please note that we do not store any
            sensitive financial information directly on our servers, and all
            transactions are encrypted and secured to protect your financial
            data.
          </p>

          <h3 className="text-sm font-semibold text-black mt-4 mb-2">c. Contacts Access</h3>
          <p className="text-xs text-black">
            With your explicit permission, we may access your device’s contacts
            in order to provide features such as inviting friends to join the
            App, sharing content directly from the App to your contacts, or
            connecting with other users who may already be part of your contact
            list. We will only access your contacts with your clear consent, and
            you can revoke this permission at any time through your device's
            privacy settings, thus ensuring that you have full control over
            the data shared.
          </p>

          <h3 className="text-sm font-semibold text-black mt-4 mb-2">
            d. Camera and Media Access
          </h3>
          <p className="text-xs text-black">
            The App may request access to your device’s camera in order to allow
            you to capture images or videos for various functionalities such as
            profile picture uploads, augmented reality interactions, or sharing
            multimedia content within the App. We do not store or share any of
            the captured media without your explicit consent, and you can manage
            the camera permissions at any time through your device's settings to
            ensure your privacy is respected.
          </p>

          <h3 className="text-sm font-semibold text-black mt-4 mb-2">e. Usage Data</h3>
          <p className="text-xs text-black">
            We collect data about how you interact with the App, including
            details such as your IP address, the type of device you're using,
            operating system details, and specific in-app activities. This
            information helps us analyze usage patterns, troubleshoot technical
            issues, and enhance the overall user experience by understanding
            which features are most popular or may need improvement.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            2. How We Use Information
          </h2>
          <p className="text-xs text-black">
            We use the information we collect to provide, operate, and maintain
            the App and its associated services. This includes personalizing
            content and services based on your preferences and location,
            ensuring that transactions are securely processed, improving user
            experience by analyzing usage patterns, and providing timely support
            when needed. Additionally, we use this data to enhance security
            measures and prevent any fraudulent or malicious activities that
            could compromise your data or the integrity of the App.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            3. Sharing Your Information
          </h2>
          <p className="text-xs text-black">
            We may share your information with third-party service providers who
            assist us in operating the App, including payment processors, data
            storage providers, and analytics services. Additionally, if required
            by law, we may disclose your data in response to legal processes
            such as court orders or regulatory requirements. We do not share
            your data with third parties for their own marketing purposes, and
            we will only share information with your consent or where it is
            necessary to provide you with the services outlined in this policy.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">4. Data Retention</h2>
          <p className="text-xs text-black">
            We retain your data only for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy, such as maintaining your
            account, providing services, and complying with legal obligations.
            Once the data is no longer needed, we securely delete or anonymize
            it to ensure that your privacy is preserved. You may request the
            deletion of your data at any time by contacting us, and we will
            process such requests in accordance with applicable laws and
            regulations.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            5. International Data Transfers
          </h2>
          <p className="text-xs text-black">
            Your data may be transferred to and processed in countries outside
            your region, including jurisdictions where privacy laws may differ
            from those in your home country. We ensure that appropriate
            safeguards are in place, such as standard contractual clauses or
            other legal mechanisms, to protect your data during international
            transfers and to comply with applicable legal standards.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            6. Security of Your Data
          </h2>
          <p className="text-xs text-black">
            We take data security very seriously and implement industry-standard
            security measures, such as encryption, firewalls, and secure data
            storage solutions, to protect your data from unauthorized access,
            disclosure, or misuse. While we strive to safeguard your
            information, please be aware that no method of electronic
            transmission or storage is completely secure, and we cannot
            guarantee its absolute security. However, we continuously review and
            enhance our security practices to minimize risks.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            7. Your Choices and Rights
          </h2>
          <p className="text-xs text-black">
            You have the right to control your data, including the ability to
            update or revoke permissions for location access, contact
            information, and camera usage via your device's settings.
            Additionally, you have the right to request access to the personal
            data we hold about you or request its deletion. To exercise any of
            these rights, please contact us, and we will respond to your request
            in accordance with applicable data protection laws.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">8. Children’s Privacy</h2>
          <p className="text-xs text-black">
            The App is not intended for use by children under the age of 16, and
            we do not knowingly collect personal data from children. If you
            believe that we have inadvertently collected data from a child under
            16 without parental consent, please contact us immediately, and we
            will take steps to remove the information from our records in
            accordance with applicable laws and regulations.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            9. Third-Party Links and Services
          </h2>
          <p className="text-xs text-black">
            The App may contain links to third-party websites or services that
            are not operated by us. We are not responsible for the privacy
            practices of these third parties, and we encourage you to review the
            privacy policies of any external sites or services before providing
            them with your personal information. Use of third-party services is
            at your own discretion and risk.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">
            10. Changes to This Privacy Policy
          </h2>
          <p className="text-xs text-black">
            We reserve the right to update this Privacy Policy from time to time
            to reflect changes in our practices, services, or legal
            requirements. Any changes to this policy will be posted within the
            App, and the updated version will become effective as soon as it is
            published. We encourage you to periodically review this policy to
            stay informed about how we are protecting your data.
          </p>

          <h2 className="text-sm font-semibold text-black mt-4 mb-2">11. Contact Us</h2>
          <p className="text-xs text-black">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or the handling of your personal data, please feel
            free to contact us at<span className="text-blue-600">support@reker.world</span>. We are committed to
            addressing your inquiries promptly and ensuring that your data
            privacy rights are respected.
          </p>
        </section>
      </div>
  );
};

export default PrivacyPolicy;
