import React, { useState } from "react";
import { CheckCircle, XCircle, ChevronDown, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom";

const DashboardFeaturesByPlan: React.FC = () => {
  const navigate = useNavigate();
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const features = [
    "Purchase history",
    "Total points earned/redeemed",
    "Advanced campaigns",
    "Store-specific AR performance",
    "Store walk-in insights",
    "Detailed rewards impact analysis",
    "Compare up to 5 stores",
    "Unlimited stores",
    "Custom branding",
    "Dedicated account manager",
  ];

  const plans = [
    {
      name: "Basic",
      price: "Free",
      description: "Perfect for single-store small businesses starting with AR marketing.",
      features: [true, true, false, false, false, false, false, false, false, false],
    },
    {
      name: "Growth",
      price: "INR 4999/month",
      description: "Up to 3 stores, featuring AR marketing & branding.",
      features: [true, true, true, true, true, true, false, false, false, true],
    },
    {
      name: "Enterprise",
      price: "INR 9999/month",
      description: "Up to 5 store businesses in a city, with advanced AR marketing & analytics.",
      features: [true, true, true, true, true, true, true, false, true, true],
    },
    {
      name: "Custom Enterprise",
      price: "Tailored",
      description: "For large businesses with 5+ stores in multiple locations and Cities.",
      features: [true, true, true, true, true, true, true, true, true, true],
    },
  ];

  const faqs = [
    {
      question: "What is Reker World Platform?",
      answer: [
        "First-of-its-kind only AR loyalty SaaS platform.",
        "Cloud-based platform for managing and enhancing customer loyalty programs.",
        "Helps businesses create, track, and optimize AR powered loyalty rewards."
      ]
    },
    {
      question: "What does Reker World offer?",
      answer: [
        "Store-Based Rewards",
        "Tiered Loyalty Programs",
        "Gamification",
        "Referral Programs",
        "Omnichannel Integration",
        "Personalized Rewards",
        "User Analytics & Insights"
      ]
    },
    {
      question: "Why Reker World?",
      answer: [
        "Boost sales with Gamified Loyalty!",
        "Users Earn, play & redeem with Newest Climate Currency AR rewards & tier-based perks.",
        "More visits = More sales."
      ]
    },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white font-sans py-16">
      <div className="container mx-auto px-6 lg:px-16">
        <h2 className="text-4xl font-bold mt-10 text-center text-yellow-400 mb-12">Explore Gamified Loyalty Program</h2>
        
        <div className="grid md:grid-cols-4 gap-8 mb-12">
          {plans.map((plan, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg text-center hover:shadow-yellow-500 transition-transform transform hover:scale-105 relative flex flex-col">
              <h3 className="text-2xl font-semibold mb-3 text-yellow-400">{plan.name}</h3>
              <p className="text-4xl font-bold mb-2">{plan.price}</p>
              <p className="text-gray-300 mb-6 text-md flex-grow">{plan.description}</p>
              <button 
                onClick={() => navigate("/contact")}
                className="bg-yellow-500 text-gray-900 font-semibold px-6 py-2 rounded-lg hover:bg-yellow-400 transition-all shadow-md mt-auto mb-2"
              >
                Reach us
              </button>
            </div>
          ))}
        </div>
        
        <div className="overflow-x-auto bg-gray-800 p-6 rounded-lg shadow-lg">
          <table className="w-full text-left border-collapse text-md">
            <thead>
              <tr className="bg-gray-700 text-yellow-400">
                <th className="px-4 py-3">Features</th>
                {plans.map((plan, index) => (
                  <th key={index} className="px-4 py-3 text-center">{plan.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {features.map((feature, featureIndex) => (
                <tr key={featureIndex} className="even:bg-gray-700 odd:bg-gray-800 border-t border-gray-700">
                  <td className="px-4 py-3 font-medium text-yellow-400">{feature}</td>
                  {plans.map((plan, planIndex) => (
                    <td key={planIndex} className="px-4 py-3 text-center text-xl">
                      {plan.features[featureIndex] ? (
                        <CheckCircle className="h-6 w-6 text-green-400 mx-auto" />
                      ) : (
                        <XCircle className="h-6 w-6 text-red-400 mx-auto" />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="container mx-auto px-6 lg:px-16 mt-16">
        <h2 className="text-3xl font-bold text-center text-yellow-400 mb-8">Know Reker World</h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-md">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleFAQ(index)}>
                <h3 className="text-xl font-semibold text-yellow-400">{faq.question}</h3>
                {openFAQ === index ? <ChevronUp className="text-yellow-400" /> : <ChevronDown className="text-yellow-400" />}
              </div>
              {openFAQ === index && (
                <ul className="list-disc pl-5 text-gray-300 mt-2">
                  {faq.answer.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardFeaturesByPlan;
